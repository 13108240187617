import { useState, React, useEffect } from 'react';
import '../../App.css';

import Notification from './notification';
import Alert from './alert';




// apps
import Contacts from '../apps/contacts/contacts';
import Calls from '../apps/calls/calls';
import Messages from '../apps/messages/messages';
import Wallet from '../apps/wallet/wallet';
import Groups from '../apps/groups/groups';
import Gang from '../apps/gang/gang';
import Info from '../apps/info/info';
import Garage from '../apps/garage/garage';
import Camera from '../apps/camera/camera';
import Settings from '../apps/settings/settings';

let PhoneisOpen = false;
let NotiCount = 0;

function Phone(props) {

  const [currentApp, setCurrentApp] = useState('home');

  const [open, setOpen] = useState('PhoneOpen'); // set to PhoneClose when not testing

  const [PhoneTime, setPhoneTime] = useState('12:00');

  const [HasVPN, setHasVPN] = useState(true);

  function handleAppChange(app) {
    setCurrentApp(app);
  }

  var TestNoti = {
    type: 'info',
    title: 'Test Notification Title',
    description: 'Test Notification Description',
    icon: 'fas fa-info',
    show : true,
    time : 5000,
  }

  const [notifications, setNotifications] = useState([
    // { 
    //   id: 1,
    //   show: true, 
    //   type: 'action', 
    //   app: 'groups',
    //   title: 'New Service Request', 
    //   description: 'Accept job offer?',
    //   time: 30000,
    // },
    // { 
    //   id: 2,
    //   show: true, 
    //   type: 'info', 
    //   title: 'Test Notification 2', 
    //   icon: 'http://imageshack.com/a/img923/2475/imwl8h.png',
    //   description: 'Test Notification Description',
    // },
    // { 
    //   id: 3,
    //   show: true, 
    //   type: 'incoming-call', 
    //   title: 'Leo Nardo', 
    //   description: 'incoming call', 
    // },
    // { 
    //   id: 4,
    //   show: true, 
    //   type: 'outgoing-call', 
    //   title: 'Leo Nardo', 
    //   description: '', 
    // },
    // { 
    //   id: 5,
    //   show: true, 
    //   type: 'active-call', 
    //   title: 'Leo Nardo', 
    //   description: '', 
    // },

  ]);

  function removeNotification(props) {
    //remove from state
    setNotifications((currentNotifications) => currentNotifications.filter((notification) => notification.id !== props.id));
    NotiCount = NotiCount - 1;

    //console.log('Notification Count: ' + NotiCount);

    if (!PhoneisOpen && NotiCount === 0) {
      setOpen('PhoneClose');
    };
  }

  function addNotification(notification) {
    // only add if notification is not already in state
    if (notifications.find((n) => n.id === notification.id)) {
      return;
    }
    setNotifications((currentNotifications) => [...currentNotifications, notification]);
    NotiCount = NotiCount + 1;
    if (notification.time !== undefined) {
      setTimeout(() => {
        removeNotification(notification); 
      }, notification.time);
    }
  }

  // useEffect(() => {
  //   addNotification(TestNoti);
  // }, []);


const [BackgroundUrl, setBackgroundUrl] = useState('http://imageshack.com/a/img923/7485/1urIlb.png');



  

  return (
    <>
      <div className='main'
      style={{
        display: props.show ? 'flex' : 'none',
      }}
      >

        <span className='phone-info-text'>
          This is a Phone Script developed for Grand Theft Auto 5 Roleplay (FiveM) <br />
          Some features require NUI events that are sent or triggered to and by the game and are non functional in browser. 
          
        </span>

        <span className='phone-test-button'
          onClick={() => {
            addNotification(TestNoti);
          }}

        >Trigger Notification</span>
        <div className='phone-frame'>
          <div className='phone-container'
            style={{
              background: `url(${BackgroundUrl})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',

            }}
          >

            <div className='notifications-container'>
              {notifications.map((notification) => (
                <div className='phone-notification'
                  key={notification.id}
                  onClick={() => 
                    // only remove if notification.type is not action or incoming-call
                    notification.type === 'info' &&
                    removeNotification(notification)
                  }
                >
                  <Notification 
                    id = {notification.id}
                    key = {notification.id}
                    type={notification.type} 
                    icon = {notification.icon}
                    image = {notification.image}
                    iconColor = {notification.iconColor}
                    message={notification.title} 
                    description={notification.description} 
                    time = {notification.time}
                    remove = {() => removeNotification(notification)}
                  />
                </div>
              ))}
            </div>

            <div className='phone-time'> {PhoneTime} </div>
            <div className='phone-wifi'> <i class="fa-solid fa-wifi"></i> </div>
            <div className='phone-battery'> <i class="fa-solid fa-battery-three-quarters fa-lg"></i> </div>
            <div className='home-button'
              onClick={() => handleAppChange('home')}
            ></div>

            <div className='phone-content'> 
              
                <div className='home-header'> {PhoneTime} </div>
                <div className="home-top">

                    <div className="app"
                        style={{ backgroundColor: 'var(--info)' }}
                        onClick={() => handleAppChange('info')}
                    ><i class="fa-solid fa-circle-info"></i></div>

                    <div className="app"
                        style={{ backgroundColor: 'var(--garage)' }}
                        onClick={() => handleAppChange('garage')}
                    ><i class="fa-solid fa-warehouse"></i></div>

                    <div className="app"
                        style={{ backgroundColor: 'var(--ping)' }}
                    ><i class="fa-solid fa-map-pin"></i></div>

                    <div className="app"
                        style={{ backgroundColor: 'var(--gang)' }}
                        onClick={() => handleAppChange('gang')}
                    ><i class="fa-solid fa-users-viewfinder"></i></div>

                    <div className="app"
                        style={{ backgroundColor: 'var(--calculator)' }}
                    ><i class="fa-solid fa-calculator"></i></div>

                    <div className="app"
                        style={{ backgroundColor: 'var(--notes)' }}
                    ><i class="fa-solid fa-note-sticky"></i></div>

                    <div className="app"
                        style={{ backgroundColor: 'var(--twatter)' }}
                    ><i class="fa-brands fa-twitter"></i></div>

                    <div className="app"
                        style={{ backgroundColor: 'var(--wallet)' }}
                        onClick={() => handleAppChange('wallet')}
                    ><i class="fa-solid fa-wallet"></i></div>

                    

                    <div className="app"
                        style={{ backgroundColor: 'var(--settings)' }}
                        onClick={() => handleAppChange('settings')}
                    ><i class="fa-solid fa-gear"></i></div>

                    
                    {/* requires VPN */}
                    <div className="app"
                        style={{ 
                          backgroundColor: 'var(--groups)', 
                          display: HasVPN ? 'flex' : 'none'
                        }}
                        onClick={() => handleAppChange('groups')}
                    ><i class="fa-solid fa-user-ninja"></i></div>

                </div>
                <div className="home-bottom">
                    <div className="app"
                        style={{ backgroundColor: 'var(--contacts)' }}
                        onClick={() => handleAppChange('contacts')}
                    ><i class="fa-solid fa-address-book"></i></div>

                    <div className="app"
                        style={{ backgroundColor: 'var(--phone)' }}
                        onClick={() => handleAppChange('calls')}
                    ><i class="fa-solid fa-phone"></i></div>

                    <div className="app"
                        style={{ backgroundColor: 'var(--messages)' }}
                        onClick={() => handleAppChange('messages')}
                    ><i class="fa-solid fa-comment"></i></div>

                    <div className="app"
                        style={{ backgroundColor: 'var(--camera)' }}
                        onClick={() => handleAppChange('camera')}
                    ><i class="fa-solid fa-camera"></i></div>

                </div>
              
                <Contacts show = {currentApp === 'contacts'} />
                <Calls show = {currentApp === 'calls'} />
                <Messages show = {currentApp === 'messages'} />
                <Wallet show = {currentApp === 'wallet'} />
                <Gang show = {currentApp === 'gang'} />
                <Info show = {currentApp === 'info'} />
                <Garage show = {currentApp === 'garage'} />
                <Camera show = {currentApp === 'camera'} />
                <Settings show = {currentApp === 'settings'} />
                <Groups show = {currentApp === 'groups'} />
            </div>
          </div>
        </div>
        <br /><br />
          Developed with React & Lua
      </div>
    </>

  );
}

export default Phone;