import React from 'react';

function Alert(props) {

    if (!props.show)
    {
        return null;
    }

    var inputs = [];

    if (props.inputs) {
        inputs = props.inputs.map((input, index) => {
            return (
                <input key={index} className='alert-input' type={input.type} placeholder={input.placeholder} />
            )
        })
    }

    if (props.type === 'input') {
        return (
            <div className="alert">
                <div className="alert-title">
                    {props.title}
                </div>
                <div className="alert-message">
                    {props.message}
                </div>

                <div className="alert-inputs">
                    {inputs}
                </div>

                <div className="alert-buttons">
                    <span className='alert-button'
                        style={{ backgroundColor: 'rgba(255, 0, 0, 0.75)' }}
                        onClick={() => props.onCancel()}
                    >Cancel</span>
                    <span className='alert-button'
                        style={{ backgroundColor: 'rgba(0, 150, 0, 0.75)' }}
                        onClick={() => {
                            var value = document.querySelector('.alert-input').value;

                            if (props.onConfirm) {
                                if (value === '') {
                                    return;
                                }
                                props.onConfirm();
                            }
                            
                        }}
                    >Confirm</span>
                </div>
            </div>
        )
    } else if (props.type === 'confirm') {
        return (
            <div className="alert">
                <div className="alert-title">
                    {props.title}
                </div>
                <div className="alert-message">
                    {props.message}
                </div>

                <div className="alert-buttons">
                    <span className='alert-button'
                        style={{ backgroundColor: 'rgba(255, 0, 0, 0.75)' }}
                        onClick={() => props.onCancel()}
                    >Cancel</span>
                    <span className='alert-button'
                        style={{ backgroundColor: 'rgba(0, 150, 0, 0.75)' }}
                        onClick={() => {

                            if (props.onConfirm) {
                                props.onConfirm();
                            }
                            
                        }}
                    >Confirm</span>
                </div>
            </div>
        )
    }

    return (
        <div className="alert">
            <div className="alert-title">
                {props.title}
            </div>
            <div className="alert-message">
                {props.message}
            </div>
        </div>
    )
}

export default Alert;