import {React, useState} from 'react';
import './homepage.css';

import Phone from '../../phone/components/phone';

function HomePage() {
    const [preview, setPreview] = useState(null);

    return (
        <div className='homepage-wrapper'>
            <div className='homepage-sidebar-wrapper'>
                <h1>Brady LeFebre</h1>
                
                <div className='preview-options-wrapper'>
                    <div className='preview-button'
                        onClick={() => {
                            setPreview('phone');
                        }}
                        style={{
                          background: 'url('+ 'http://imageshack.com/a/img923/7088/Y1oAUt.png' + ')',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center'
                        }}
                    >Phone</div>
                </div>
                

            </div>

            <div className='homepage-content-wrapper'>
                <Phone show={preview === 'phone'} />
            </div>
        </div>
    )
}

export default HomePage;