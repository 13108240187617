import { useState, React } from 'react';

import "./info.css";



function Info(props) {

    const [info, setInfo] = useState({
        name: 'John Doe',
        citizenid: 'FAX87987',
        serverid: '2',
        phone: '1234567890',
        reputation: '0'
    });

    const [hasVPN, setHasVPN] = useState(false);


        if (!props.show) {
            return null;
        }
    
    return (
            <div className="app-container" id="info-app">
                <span className='info-title'>My Info</span>
                <div className='info-item-wrapper'>
                    <span>Name:</span>
                    <span>{info.name}</span>
                </div>
                <div className='info-item-wrapper'>
                    <span>Citizen ID:</span>
                    <span>{info.citizenid}</span>
                </div>
                <div className='info-item-wrapper'>
                    <span>Server ID:</span>
                    <span>{info.serverid}</span>
                </div>
                <div className='info-item-wrapper'>
                    <span>Phone:</span>
                    
                    <span>{info.phone}</span>
                </div>
                <div className='info-item-wrapper'
                    style={{
                        display: hasVPN ? 'flex' : 'none'
                    }}
                >
                    <span>Reputation:</span>
                    <span>{info.reputation}</span>
                </div>

                <div className='info-actions-wrapper'>
                    <div className='action-wrapper'>
                        <span className='info-action'
                            onClick={() => {
                            }}
                        >
                            <i class="fa-solid fa-tower-broadcast"></i>
                            
                        </span>
                        <span>Share Contact Info</span>
                    </div>
                    
                </div>
            </div>
    );
}

export default Info;