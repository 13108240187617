import { useState, React } from 'react';

import "./settings.css";


function Settings(props) {

    const [wallpaper, setWallpaper] = useState('');

    function ChangeWallpaper(url) {
        //console.log('Changing wallpaper');
    }

        if (!props.show) {
            return null;
        }
    
    return (
            <div className="app-container" id="settings-app">
                <span className='settings-title'><i class="fa-solid fa-gear"></i></span>

                <span className='settings-info'> Version: 1.10</span>
                <span className='settings-info'> Serial: KQFP9384J7T</span>
                <span className='settings-info'> Storage: 64 GB</span>
                <span className='settings-info'> Firmware: 1.60.07</span>

                <div className='wallpaper-container'>
                    <span className='wallpaper-title'>Wallpaper</span>
                    
                    <span className='wallpaper-wrapper'>
                        
                        <span className='custom-title'>Custom:</span>
                        <input type="text" className='wallpaper-input' placeholder='URL (No Imgur)'
                            onChange={(e) => setWallpaper(e.target.value)}
                        />
                    </span>
                    <span className='wallpaper-submit'
                        onClick={() => ChangeWallpaper(wallpaper)}
                    >Submit</span>

                    <span className='presets-title'> Presets</span>
                    <div className='presets-wrapper'>
                        <span className='preset'
                            onClick={() => ChangeWallpaper('http://imageshack.com/a/img924/5369/g5fI28.png')}
                        >
                            <img src="http://imageshack.com/a/img924/5369/g5fI28.png" alt="preset-1" width={'100%'}/>
                        </span>
                        <span className='preset'
                            onClick={() => ChangeWallpaper('http://imageshack.com/a/img924/6871/YkjFpX.png')}
                        >
                            <img src="http://imageshack.com/a/img924/6871/YkjFpX.png" alt="preset-2" width={'100%'}/>
                        </span>
                        <span className='preset'
                            onClick={() => ChangeWallpaper('http://imageshack.com/a/img923/8280/nUeGrm.png')}
                        >
                            <img src="http://imageshack.com/a/img923/8280/nUeGrm.png" alt="preset-3" width={'100%'}/>
                        </span>
                    </div>
                </div>
                
                

            </div>
    );
}

export default Settings;