
import "./contacts.css";

import { useState } from "react";



function Contacts(props) {
    const [addContact, setAddContact] = useState(false);
    const [contactName, setContactName] = useState('');
    const [contactNumber, setContactNumber] = useState('');

    const [contacts, setContacts] = useState([
        {
            name: 'John Doe',
            number: '123-456-7890'
        },
    ]);

    const [preEditContact, setPreEditContact] = useState({ name: '', number: '' });
    const [editContact, setEditContact] = useState({
        active: false,
        name: '',
        number: ''
    });

    function toggleAddContact() {
        setAddContact(!addContact);
    }
    
        if (!props.show) {
            return null;
        }

    
    function ContactItem(props) {
        return (
            <div className="contact-wrapper">
                <div className="contact-icon" 
                    onClick={() => {
                        setEditContact({
                            active: true,
                            name: props.name,
                            number: props.number
                        });
                        setPreEditContact({
                            name: props.name,
                            number: props.number
                        });
                    }}
                ><i class="fa-solid fa-user"></i></div>

                <div className="contact-info">
                    <div className="contact-name-top">{props.name}</div>
                    <div className="contact-number">{props.number}</div>
                </div>
                <div className="contact-options">
                    <div className="contact-option"
                    ><i class="fa-solid fa-phone"></i></div>
                    <div className="contact-option"
                    ><i class="fa-solid fa-comment"></i></div>
                </div>
                
            </div>
        );
    }
    
    return (
            <div className="app-container" id = "contacts-app">
                <div className="add-contact-wrapper"
                    style={{ display: addContact ? 'flex' : 'none' }}
                >
                    <span className="add-contact-title">Add Contact</span>
                    <input className="contact-input" type="text" placeholder="Name" 
                        onChange={(e) => setContactName(e.target.value)}
                    />
                    <input className="contact-input" type="text" placeholder="#" maxlength="10"
                        onChange={(e) => {
                            // var value = e.target.value;
                            // // Allow only numeric input
                            // e.target.value = value.replace(/[^0-9]/g, '');
                            setContactNumber(e.target.value);

                        }}
                    />
                    
                    <div className="contact-finish-buttons">
                        <div className="contact-cancel-button"
                            onClick={toggleAddContact}
                            setContactName={''}
                            setContactNumber={''}

                            // reset input value 
                            
                        >Cancel</div>
                        <div className="contact-save-button"
                            onClick={() => {
                                //setContacts([...contacts, { name: contactName, number: contactNumber }]);
                                setContactName('');
                                setContactNumber('');
                                toggleAddContact();
                            }}

                        >Save</div>
                    </div>
                    
                </div>

                <div className="edit-contact-wrapper"
                    style={{ display: editContact.active ? 'flex' : 'none' }}
                >
                    <span className="add-contact-title">Edit Contact</span>
                    <input className="contact-input" type="text" placeholder="Name" value={editContact.name}
                        onChange={(e) => setEditContact({ ...editContact, name: e.target.value })}
                    />
                    <input className="contact-input" type="text" placeholder="#" maxlength="10" value={editContact.number}
                        onChange={(e) => {
                            // var value = e.target.value;
                            // // Allow only numeric input
                            // e.target.value = value.replace(/[^0-9]/g, '');
                            setEditContact({ ...editContact, number: e.target.value });

                        }}
                    />
                    
                    <div className="contact-finish-buttons">
                        <div className="contact-cancel-button"
                            onClick={() => {
                                setEditContact({
                                    active: false,
                                    name: '',
                                    number: ''
                                });
                                setPreEditContact({
                                    name: '',
                                    number: ''
                                });
                            }}

                            // reset input value 
                            
                        >Cancel</div>
                        <div className="contact-save-button"
                            onClick={() => {
                                //setContacts([...contacts, { name: contactName, number: contactNumber }]);
                            
                        

                                setEditContact({
                                    active: false,
                                    name: '',
                                    number: ''
                                });

                                setPreEditContact({
                                    name: '',
                                    number: ''
                                });

                            }}

                        >Save</div>
                    </div>

                    <div className="contact-delete-button"
                        onClick={() => {
                            setEditContact({
                                active: false,
                                name: '',
                                number: ''
                            });

                        }}
                    >Delete Contact</div>
                    
                </div>
                <div className="contacts-top">
                    <span
                        style={{ marginRight: '25px' }}
                    ><i class="fa-solid fa-address-book"></i></span> 
                    
                    Contacts 
                    
                    <span className="contact-add"
                        onClick={toggleAddContact}
                    ><i class="fa-solid fa-circle-plus"></i></span>
                </div>
                <div className="contacts-bottom">
                    {Array.isArray(contacts) && contacts.map(contact => (
                        <ContactItem name={contact.name} number={contact.number} />
                    ))}

                </div>
            </div>
    );
}

export default Contacts;