import { useState, React } from 'react';

import "./camera.css";


function Camera(props) {

    const [gallery, setGallery] = useState([
        { url : 'http://imageshack.com/a/img922/2882/WIEd0k.jpg' },

    ]);

    const [showGallery, setShowGallery] = useState(false);
    const [showPreview, setShowPreview] = useState(false);


        if (!props.show) {
            return null;
        }
    
    return (
        <div className="app-container" id="camera-app">

            <div className='image-preview'
                onClick={() => {
                    setShowPreview(false);
                }}
                style={{ display: showPreview ? 'flex' : 'none' }}

            >
                <img src={showPreview} alt='preview' width={'100%'} />
            </div>

            <div className='gallery-wrapper'
                style={{ display: showGallery ? 'flex' : 'none' }}
            >
                <div className='gallery-header'>
                    <span>Gallery</span>
                    <span className='gallery-close'
                        onClick={() => {
                            setShowGallery(false);
                        }
                    }
                    ><i class="fa-solid fa-square-xmark"></i></span>
                </div>
                <div className='gallery-items-wrapper'>
                    {gallery.map((item, index) => {
                        return (
                            <div className='gallery-item' key={index}
                                style={{
                                    backgroundImage: `url(${item.url})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center'
                                }}
                                onClick={() => {
                                    setShowPreview(item.url);
                                }}
                            >
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className='camera-option'
                onClick={() => {
                    setShowGallery(true);
                }}
            ><span>Gallery &nbsp; <i class="fa-solid fa-image"></i></span></div>

            <div className='camera-option'
            >
                <span>Camera &nbsp; <i class="fa-solid fa-camera"></i></span>
                

            </div>
            
            <span className='cam-info'> 
                    Camera Controls
                    <span className='cam-info-item'>
                        <span className='camera-key'><i class="fa-solid fa-arrow-up"></i></span> Toggle Selfie Mode
                    </span>
                    <span className='cam-info-item'>
                        <span className='camera-key'>Enter </span>Take Photo
                    </span>
                    <span className='cam-info-item'>
                        <span className='camera-key'>Backspace</span> Close
                    </span>
            </span>
        </div>
    );
}

export default Camera;