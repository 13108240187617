import { useState, useEffect, React } from 'react';
import "./messages.css";

function Conversation(props) {
    return (
        <>
            <div className="conversation-read">
                {props.read === 1 ? null : <i className="fa-solid fa-circle"></i>}
            </div>
            <div className="conversation-title">{props.title}</div>
            <div className="conversation-last-message">{props.lastMessage || ''}</div>
        </>
    );
}

function MessageItem(props) {
    return (
        <div className={props.sender === "me" ? "message-me" : "message"}>
            <div className="message-text">{props.message}</div>
        </div>
    );
}

function Messages(props) {
    const [view, setView] = useState("conversations-list");
    const [currentConvo, setCurrentConvo] = useState(null);
    const [myConversations, setMyConversations] = useState([
        {
            name: "John Doe",
            number: "5555555555",
            messages: [
                { message: "Hey, how's it going?", sender: "John Doe" },
                { message: "I'm doing great, thanks for asking!", sender: "me" },
                { message: "Do you want to hang out?", sender: "me" },
                { message: "Sure, when?", sender: "John Doe" },
                { message: "How about 7pm?", sender: "me" },
                { message: "Sounds good!", sender: "John Doe" },
                { message: "See you then!", sender: "John Doe" },
                { message: "See you then!", sender: "me" },
            ],
            read: 0
        },
        {
            name: "Jane Doe 1",
            number: "4444444444",
            messages: [
                { message: "Hey, how's it going?", sender: "Jane Doe" },
                { message: "I'm doing great, thanks for asking!", sender: "me" },
            ],
            read: 1
        },
    ]);

    function AddNewConvo(convo) {
        setMyConversations([...myConversations, convo]);
    }

    function handleViewChange(view) {
        setView(view);
    }

    function handleConvoChange(convo) {
        setCurrentConvo(convo);
    }

    function addMessageToCurrentConvo(message) {
        if (!currentConvo) return;

        const updatedConvo = { ...currentConvo, messages: [...currentConvo.messages, { message, sender: "me" }] };
        const updatedConversations = myConversations.map(convo =>
            convo.name === currentConvo.name ? updatedConvo : convo
        );

        setCurrentConvo(updatedConvo);
        setMyConversations(updatedConversations);
    }

    const [showMessageContext, setShowMessageContext] = useState(false);

    const scrollToBottom = () => {
        const element = document.querySelector(".conversation-content");
        if (!element) return;
        element.scrollTop = element.scrollHeight;
    };

    useEffect(() => {
        scrollToBottom();
    }, [currentConvo]);

    const [contacts, setContacts] = useState([
        {
            name: 'John Doe',
            number: '5555555555'
        },
    ]);


    const [showDropdown, setShowDropdown] = useState(false);

    const sendMessage = () => {
        const value = document.querySelector('.message-type').value;

        if (!value) return;

        var formattedMessage = value;

        addMessageToCurrentConvo(formattedMessage);

        // clear input
        document.querySelector('.message-type').value = '';
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            //console.log('Enter key pressed');
            e.preventDefault(); // Prevent the default newline behavior
            sendMessage();
        }
    };

    if (!props.show) {
        return null;
    }

    return (
        <div className="app-container" id="messages-app">

            <div className='message-context-wrapper'
                style={showMessageContext ? { display: "flex" } : { display: "none" }}
            >
                <span className='message-context-header'>Start New Conversation</span>

                <div className='number-input-wrapper'>
                    <span>#:</span> <input type='text' className='new-message-number-input'></input> 
                    <span className='start-new-convo'
                        onClick={() => {
                            const number = document.querySelector('.new-message-number-input').value;
                            if (!number) return;
                            // make sure input is a number and has 10 digits
                            if (isNaN(number) || number.length !== 10) return;
                            
                            
                            setShowMessageContext(false);

                            // check if existing convo
                            var convo = myConversations.find(convo => convo.number === number);
                            if (convo) {
                                handleViewChange("conversation");
                                handleConvoChange(convo);
                                return;
                            }

                            var convo = {
                                name: number,
                                number: number,
                                messages: [],
                                read: 0
                            };

                            handleViewChange("conversation");
                            handleConvoChange(convo);
                        }}
                    ><i class="fa-solid fa-comment"></i></span>
                </div>
                <div className='contacts-dropdown'
                    onClick={() => setShowDropdown(true)}
                >
                    <span className='contacts-button'>Contacts</span>
                    <select className='contacts-dropdown-select'
                        style={showDropdown ? { display: "flex" } : { display: "none" }}
                    >
                        {contacts && contacts.map((contact) => (
                            <option value={contact.number}>{contact.name}</option>
                        ))}
                    </select>
                    <span className='start-new-convo'
                        onClick={() => {
                            const number = document.querySelector('.contacts-dropdown-select').value;
                            if (!number) return;
                            
                            setShowMessageContext(false);

                            // check if existing convo
                            var convo = myConversations.find(convo => convo.number === number);
                            if (convo) {
                                handleViewChange("conversation");
                                handleConvoChange(convo);
                                return;
                            }

                            var convo = {
                                name: contacts.find(contact => contact.number === number).name,
                                number: number,
                                messages: [],
                                read: 0
                            };

                            handleViewChange("conversation");
                            handleConvoChange(convo);
                        }}
                    ><i class="fa-solid fa-comment"></i></span>

                    
                </div>

                <span className='close-context-message'
                    onClick={() => setShowMessageContext(false)}
                >Close</span>
            </div>

            <div className="messages-header">
                <div className='back-arrow'
                    onClick={() => handleViewChange("conversations-list")}
                    style={view === "conversation" ? { display: "flex" } : { display: "none" }}
                >
                    <i className="fa-solid fa-arrow-left"></i>
                </div>

                <div className="messages-title"
                    style={view === "conversations-list" ? { display: "flex" } : { display: "none" }}
                ><span>Messages</span> 
                
                <span className='new-convo'
                    onClick={() => setShowMessageContext(!showMessageContext)}
                ><i class="fa-solid fa-pen-to-square"></i></span>
                
                </div>
                
                <div className="messages-title"
                    style={view === "conversation" ? { display: "flex" } : { display: "none" }}
                >
                    {currentConvo ? currentConvo.name : "Messages"}
                </div>
            </div>

            <div className="conversations"
                style={view === "conversations-list" ? { display: "flex" } : { display: "none" }}
            >
                {myConversations && myConversations.slice().reverse().map((convo) => {
                    return (
                        <div className="conversation-item" key={convo.name}
                            onClick={() => {
                                handleViewChange("conversation");
                                handleConvoChange(convo);
                            }}
                        >
                            <Conversation title={convo.name || convo.number} lastMessage={convo.messages[convo.messages.length - 1].message || ''} read={convo.read} />
                        </div>
                    );
                })}
            </div>

            <div className="conversation-wrapper"
                style={view === "conversation" ? { display: "flex" } : { display: "none" }}
            >
                <div className="conversation-content">
                    {currentConvo && currentConvo.messages && currentConvo.messages.length > 0 && currentConvo.messages.map((message, index) => (
                        <MessageItem key={index} message={message.message} sender={message.sender} />
                    ))}
                </div>

                <div className='message-input'>
                    <textarea placeholder="Type a message..." className='message-type' onKeyDown={handleKeyDown}></textarea>
                    <span className='send-message-button'
                        onClick={() => {
                            const value = document.querySelector('.message-type').value;

                            if (!value) return;

                            var formattedMessage = value;

                            addMessageToCurrentConvo(formattedMessage);

                            // clear input
                            document.querySelector('.message-type').value = '';
                        }}
                        
                    ><i className="fa-solid fa-paper-plane"></i></span>
                </div>
            </div>
        </div>
    );
}

export default Messages;
