import "./groups.css";
import { useState } from "react";
import { useEffect } from "react";

import Alert from "../../components/alert";

function Groups(props) {

    const [tab, setTab] = useState("jobs");
    const [showAlert, setShowAlert] = useState(false);
    const [jobs, setJobs] = useState([
        {
            label: 'Vangelicos',
            job: 'jewelery',
            image: 'http://imageshack.com/a/img922/9288/DRsfA5.jpg',
            reputation: 400,
        },
        {
            label: 'Fleeca',
            job: 'fleeca',
            image: 'http://imageshack.com/a/img922/3677/87HfSz.jpg',
            status: 'closed',
            reputation: 700,
            min: 1,
            max: 4,
            locations: ['Vinewood', 'Rockford Hills', 'Del Perro', 'Pillbox Hill']
        },
        {
            label: 'Paleto',
            job: 'paleto',
            image: 'http://imageshack.com/a/img924/7263/67RmG8.jpg',
            reputation: 3700,

        },
        {
            label: 'Bobcat',
            job: 'bobcat',
            locked: true,
            image: 'http://imageshack.com/a/img923/750/qODkrJ.jpg',
        },
        {
            label: 'Pacific',
            job: 'pacific',
            image: 'http://imageshack.com/a/img923/9488/qY713b.jpg',
            reputation: 5700,
        }
    ])

    const [LeaveButton, setLeaveButton] = useState('Leave Group')

    const [selectedJob, setSelectedJob] = useState([{label: 'Selected Job', job: 'sanitation'}])

    const [serverId, setServerId] = useState('')
    
    const [selectedGroup, setSelectedGroup] = useState('Selected Group')
    const [isLeader, setIsLeader] = useState(true)
    const [MyReputation, setReputation] = useState(700)
    const [JobStarted, setJobStarted] = useState(false)

    const [groups, setGroups] = useState([
        {
            name: 'Group 1',
            min: 1,
            max: 4,
            MemberCount: 4,
            job: 'sanitation'
        },
        {
            name: 'Group 2',
            job: 'towing',
            min: 1,
            max: 4,
            MemberCount: 3
        },
    ])
    const [MyGroup, setMyGroup] = useState([
        {
            name: 'Group with longer name',
            job: 'Sanitation',
            leader: {
                source: 1,
                name: 'John Doe'
            },
            min: 1, 
            max: 4,
            MemberCount: 4,
            members: []
        }
    ])

    function handleJobSelect(job) {
        setSelectedJob(job);
    }

    function handleTabChange(tab) {
        setTab(tab);
    }

    function handleAlert() {
        setShowAlert(true);
    }


    function JobItem(props) {
        return (
            <div className="jobs-item"
                style={{
                    background: props.image && !props.locked ? `url(${props.image})` : 'rgba(0,0,0,0.75)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat'
                }}
            >
                <span className="job-title">{props.title}</span>
                <span className="job-button"
                    onClick={() => {
                        if (props.locked || !props.hasrep) {
                            return;
                        }

                        //console.log('selecting job', props.job);

                        handleTabChange("groups");
                        handleJobSelect(props.job);
                    }}
                    
                >{props.locked || !props.hasrep ? <i class="fa-solid fa-lock"></i> : <i class="fa-solid fa-people-group"></i>}</span>
            </div>
        )
    }

    function GroupItem(props) {
        return (
            <div className="group-item"
                onClick={() => {
                }}
            >
                <span
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "35%",
                        marginRight: "10px"
                    }}
                ><i class="fa-solid fa-user-group"></i>({props.MemberCount}/{props.max})</span>
                <span className="group-title">{props.title}</span>
            </div>
        )
    }

    function MemberItem(props) {
        return (
            <div className="member-item">
                <span className="member-title">{props.name}</span>
                <span className="member-remove"
                    style={{
                        display: isLeader ? "flex" : "none"
                    }}
                    onClick={() => {
                        console.log(isLeader, props.id)
                    }}
                > <i class="fa-solid fa-square-xmark"></i></span>
            </div>
        )
    }

    {
        if (!props.show)
        {
            return null;
        }
        return (
            <>
                <div className="app-container" id="groups-app">
                    <Alert title='Create Group?' show={showAlert} type='confirm' 

                        onCancel={() => setShowAlert(false)}
                        onConfirm={() => {
                            setShowAlert(false);

                            //setTab("my-group");
                        }}
                    />

                    <span className="jobs-title"
                        style={{
                            display: tab === "jobs" ? "flex" : "none"
                        }}
                    >Available Jobs</span>
                    <span className="groups-title"
                        style={{
                            display: tab === "groups" ? "flex" : "none"
                        }}
                    ><span className="groups-back-button"
                        onClick={() => handleTabChange("jobs")}
                    ><i class="fa-solid fa-left-long"></i></span> 
                    
                    <span>{selectedJob.label}</span>

                    <span className="groups-create"
                        onClick={handleAlert}
                    ><i class="fa-solid fa-people-group"
                        style={{marginRight: "10px"}}
                    ></i> Create </span>
                    
                    </span>
                    <div className="jobs-list"
                        style={{
                            display: tab === "jobs" ? "flex" : "none"
                        }}
                    >
                        {Array.isArray(jobs) && jobs.map(job => (
                            (job.hasrep) &&
                            <JobItem 
                                key={job.label}
                                title={job.label}
                                job = {job}
                                image={job.image}
                                reputation={job.reputation}
                                hasrep = {job.hasrep}
                            />
                            
                        ))}

                        {Array.isArray(jobs) && jobs.map(job => (
                            (job.hasrep === false && !job.locked) &&
                            <JobItem 
                                key={job.label}
                                title={'???'}
                                job = {job}
                                image = {false}
                                reputation={job.reputation}
                                hasrep = {job.hasrep}
                            />
                            
                        ))}

                        {Array.isArray(jobs) && jobs.map(job => (
                            (job.locked === true) &&
                            <JobItem 
                                key={job.label}
                                title= "Coming Soon..." //@7#k$1X&!3
                                job = {job}
                                image={job.image}
                                locked = {job.locked}
                            />
                            
                        ))}

                    </div>

                    <div className="groups-list"
                        style={{
                            display: tab === "groups" ? "flex" : "none",
                        }}
                    >
                        <span className="selected-job-image">
                            <img src={selectedJob.image} alt="job" className="job-image" width={'100%'} />
                        </span>

                        <span className="job-requirement">
                            <span>Status: </span>
                            <span
                                style={{
                                    display: selectedJob.status === "open" ? "flex" : "none",
                                    color: "green",
                                    fontSize: "20px"
                                }}
                            ><i class="fa-solid fa-square-check"></i></span>
                            <span
                                style={{
                                    display: selectedJob.status === "closed" ? "flex" : "none",
                                    color: "red",
                                    fontSize: "20px"
                                }}
                            ><i class="fa-solid fa-square-xmark"></i></span>
                        </span>
                        <span className="job-requirement">
                            <span>Reputation Required: </span>
                            <span>{selectedJob.reputation}</span>
                        </span>
                        
                        <span className="job-requirement">
                            <span>Min Group Size:</span>
                            <span>{selectedJob.min}</span>
                        </span>

                        <span className="job-requirement">
                            <span>Max Group Size:</span>
                            <span>{selectedJob.max}</span>
                        </span>

                        

                    </div>

                    <div className="mygroup"
                        style={{
                            display: tab === "mygroup" ? "flex" : "none"
                        }}
                    >
                        <div className="mygroup-title">
                            <span>
                                <span
                                style={{
                                    marginRight: "10px"
                                }}>My Group</span>
                                
                                <span>{MyGroup[0].MemberCount}/{MyGroup[0].max}</span>
                            </span> 
                            <span className="mygroup-disband"
                                onClick={() => {
                                }}
                            >{LeaveButton}</span>

                        </div>
                        <div className="group-invite"
                            style={{
                                display: isLeader ? "flex" : "none"
                            }}
                        >
                            <input type="text" placeholder="Server ID" className="group-invite-input"
                                onChange={(e) => {
                                    // notify server id was invited
                                    setServerId(e.target.value);
                                }}
                            />
                            <span className="group-invite-button"
                                onClick={() => {
                                }}
                            >Invite</span>
                        </div>
                        
                        <div className="job-info-wrapper"
                            style={{
                                background: `url(${selectedJob.image})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat'

                            }}
                        >
                            <span className="job-info-item">
                                <span className="job-item-title">Job:</span>
                                <span>{selectedJob.label}</span>
                            </span>
                            <span className="job-info-item">
                                <span className="job-item-title">Reputation Reward:</span>
                                <span>1000</span>
                            </span>
                            
                            <span className="start-job-button"
                                onClick={() => {
                                    if (!isLeader) {
                                        return;
                                    }

                                    if (JobStarted) {
                                        return;
                                    }

                                }}
                            >
                                {JobStarted ? 'Job In Progress' : 'Start Job'}
                            </span>

                        </div>
                        
                        <div className="mygroup-members">

                            <div className="member-item">
                                <span className="member-title"><i class="fa-solid fa-crown"></i> &nbsp;{MyGroup[0].leader.name}</span>
                            </div>

                            {Array.isArray(MyGroup[0].members) && MyGroup[0].members.map(member => (
                                (member.source !== MyGroup[0].leader.source) &&
                                <MemberItem 
                                    key={member.source}
                                    name={member.name}
                                    id={member.source}
                                />
                            ))}
                        </div>
                    </div>
                </div>

                
            </>
        );
    }
}

export default Groups;