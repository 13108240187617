import { useState, React } from 'react';

import "./calls.css";


function Phone(props) {

    const [numberInput, setNumberInput] = useState('');
    const [showRecentCalls, setShowRecentCalls] = useState(false);
    const [recentCalls, setRecentCalls] = useState([
        {
            name: 'John Doe 1',
            number: '1234567890',
            date: '12/31/2021',
            time: '12:00 PM',
            missed: true
        },
    ]);

    function handleNumberInput(number) {
        if (numberInput.length < 10) {
            setNumberInput(numberInput + number);
        }
    }

    function RecentCallItem(props) {
        return (
            <div className='recent-call-item'>

                <span>
                    <span className='recent-call-item-name'
                        style={{
                            color: props.missed ? 'rgba(255, 0, 0, 0.75)' : 'white'
                        }}
                    >{props.name}</span>

                    <span className='recent-call-item-date'>
                        <span>{props.date}</span>
                        <span>{props.time}</span>
                    </span>
                </span>
                
                
                <span className='recent-call-actions'>
                    <span className='recent-call-action'
                        
                    ><i class="fa-solid fa-phone-flip"></i></span>
                </span>
            </div>
        );
    }
    


        if (!props.show) {
            return null;
        }
    
    return (
            <div className="app-container" id="phone-app">

                <div className='recent-calls-wrapper'
                    style={{
                        display: showRecentCalls ? 'flex' : 'none'
                    }}
                >
                    <div className='recent-calls-header'>
                        <span>Recent Calls</span>
                        <span className='close-recent-calls'
                            onClick={ () => setShowRecentCalls(false)}
                        ><i class="fa-solid fa-square-xmark"></i></span>
                    </div>

                    <div className='recent-calls-list-wrapper'>
                        {recentCalls.slice().reverse().map((call, index) => (
                            <RecentCallItem
                                key={index}
                                name={call.name}
                                number={call.number}
                                date={call.date}
                                time={call.time}
                                missed={call.missed}
                            />
                        ))}
                    </div>
                </div>


                <div className='dial-pad'>
                    <span className='number-input'>
                        <span> {numberInput} </span> <span className='number-paste'
                            onClick={ () => navigator.clipboard.readText().then(text => 
                                // make sure text is a number

                                (text.match(/^[0-9]+$/) != null) && (text.length <= 10) ? setNumberInput(text) : null

                            )}
                            style={{
                                fontSize: '20px',
                                cursor: 'pointer',
                                marginLeft: '10px'
                            }}
                        ><i class="fa-solid fa-paste"></i></span>
                    </span>
                    
                    <div className='dial-pad'>
                        <div className='dial-pad-row'>
                            <div className='dial-pad-button'
                                onClick={ () => handleNumberInput('1')}
                            >1</div>
                            <div className='dial-pad-button'
                                onClick={ () => handleNumberInput('2')}
                            >2</div>
                            <div className='dial-pad-button'
                                onClick={ () => handleNumberInput('3')}
                            >3</div>
                        </div>
                        <div className='dial-pad-row'>
                            <div className='dial-pad-button'
                                onClick={ () => handleNumberInput('4')}
                            >4</div>
                            <div className='dial-pad-button'
                                onClick={ () => handleNumberInput('5')}
                            >5</div>
                            <div className='dial-pad-button'
                                onClick={ () => handleNumberInput('6')}
                            >6</div>
                        </div>
                        <div className='dial-pad-row'>
                            <div className='dial-pad-button'
                                onClick={ () => handleNumberInput('7')}
                            >7</div>
                            <div className='dial-pad-button'
                                onClick={ () => handleNumberInput('8')}
                            >8</div>
                            <div className='dial-pad-button'
                                onClick={ () => handleNumberInput('9')}
                            >9</div>
                        </div>
                        <div className='dial-pad-row'>
                            <div className='dial-pad-button'
                                onClick={ () => handleNumberInput('*')}
                            >*</div>
                            <div className='dial-pad-button'
                                onClick={ () => handleNumberInput('0')}
                            >0</div>
                            <div className='dial-pad-button'
                                onClick={ () => handleNumberInput('#')}
                            >#</div>
                        </div>
                        <div className='dial-pad-row' id='dial-pad-row-bottom'>

                            <div className='dial-pad-button' id='dial-pad-button-recent'
                                onClick={ () => setShowRecentCalls(!showRecentCalls)}
                            ><i class="fa-solid fa-list"></i></div>

                            <div className='dial-pad-button' id='dial-pad-button-call'
                                onClick={ () => 
                                    {
                                        if (numberInput.length > 0) {
                                            //props.callNumber(numberInput)
                                            setNumberInput('')
                                        }
                                    }
                                }
                            ><i class="fa-solid fa-phone-flip"></i></div>
                            <div className='dial-pad-button' id='dial-pad-button-delete'
                                onClick={ () => setNumberInput(numberInput.slice(0, -1))}
                            ><i class="fa-solid fa-delete-left"></i></div>
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default Phone;