import React, { useState, useEffect } from 'react';

function CountdownTimer(props) {
    // Convert props.time from milliseconds to seconds
    const initialTimeInSeconds = props.time / 1000;

    // State to hold the current time left
    const [timeLeft, setTimeLeft] = useState(initialTimeInSeconds);

    useEffect(() => {
        // Set the time left to the initial time when props.time changes
        setTimeLeft(initialTimeInSeconds);
    }, [props.time, initialTimeInSeconds]);

    useEffect(() => {
        // Exit early when we reach 0
        if (timeLeft <= 0) {
            return;
        }

        // Save intervalId to clear the interval when the component re-renders
        const intervalId = setInterval(() => {
            // Decrement the time left
            setTimeLeft(timeLeft - 1);
        }, 1000);

        // Clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // Add timeLeft as a dependency to re-run the effect when it changes
    }, [timeLeft]);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        // Pad the minutes and seconds with leading zeros if necessary
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <div className='notification-time'>
            {/* Display the countdown timer */}
            {formatTime(timeLeft)}
        </div>
    );
}

function CountUpTimer(props) {
    // State to hold the current time in seconds
    const [time, setTime] = useState(0);

    useEffect(() => {
        // Interval to increment the time every second
        const intervalId = setInterval(() => {
            setTime(prevTime => prevTime + 1);
        }, 1000);

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, []); // Empty dependency array means this effect runs only once on mount

    // Function to format the time in 00:00 format
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        // Pad the minutes and seconds with leading zeros if necessary
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
            <div className='notification-time'>
                {/* Display the formatted time */}
                {formatTime(time)}
            </div>
    );
}


function Notification(props) {

    if (props.type === 'action') {
        return (
            <>
                <div className='notification-icon'>
                    {props.icon && <i className={"fa-solid fa-" + props.icon}></i>}
                    {props.image && <img src={props.image} alt="noti-icon" style={{width: "100%"}} />}
                    {/* {props.app && <i class="fa-solid fa-circle-info"></i>} */}
                </div>


                <div className='notification-message'>
                    {props.time && <div className='notification-time'>
                            <CountdownTimer time={props.time} />
                        </div>}
                    <div className='notification-title'>
                        {props.message}
                    </div>

                    <div className='notification-description'>
                        {props.description}
                        
                    </div>
                </div>

                <div className='notification-action'>
                    <div className='notification-yes'
                        style={{color: 'green'}}
                        onClick={() => {
                            props.remove();
                        }}
                    ><i class="fa-solid fa-circle-check"></i></div>
                    <div className='notification-no'
                        style={{color: 'red'}}
                        onClick={() => {
                            props.remove();
                        }}
                    ><i class="fa-solid fa-circle-xmark"></i></div>        
                </div>
                
            </>
            
        )
    } else if (props.type === 'info') {
        return (
            <>
                <div className='notification-icon'>
                    {props.image ? <img src={props.image} alt="noti-icon" style={{width: "100%"}} /> : null}
                    {props.icon ? <i className={"fa-solid fa-" + props.icon}></i> : null}
                    {!props.image && !props.icon && <i className="fa-solid fa-info"></i>}
                    {/* {props.app && <i class="fa-solid fa-circle-info"></i>} */}
                </div>


                <div className='notification-message'
                    style={{width: '75%'}}
                >
                    <div className='notification-title'>
                        {props.message}
                    </div>

                    <div className='notification-description'>
                        {props.description}
                    </div>
                </div>

                <div className='notification-action'
                    style={{width:0}}
                ></div>
            </>
        )
    } else if (props.type === 'incoming-call') {
        return (
            <>
                <div className='notification-icon'>
                    <i class="fa-solid fa-phone fa-shake"></i>
                </div>


                <div className='notification-message'>
                    <div className='notification-title'>
                        {props.message}
                    </div>

                    <div className='notification-description'>
                        {props.description}
                    </div>
                </div>

                <div className='notification-action'>
                    <div className='notification-yes'
                        style={{color: 'green'}}
                        onClick={() => {
                            props.remove();
                        }}
                    ><i class="fa-solid fa-circle-check"></i></div>
                    <div className='notification-no'
                        style={{color: 'red'}}
                        onClick={() => {
                            props.remove();
                        }}
                    ><i class="fa-solid fa-circle-xmark"></i></div>        
                </div>
            </>
        )
    } else if (props.type === 'outgoing-call') {
        return (
            <>
                <div className='notification-icon'>
                    <i class="fa-solid fa-phone"></i>
                </div>


                <div className='notification-message'>
                    <div className='notification-title'>
                        {props.message}
                    </div>

                    <div className='notification-description'>
                        {props.description} {<CountUpTimer description={props.description} />}
                    </div>
                </div>

                <div className='notification-action'>
                    <div className='notification-no'
                        style={{color: 'red'}}
                        onClick={() => {
                            props.remove();
                        }}
                    ><i class="fa-solid fa-phone-slash"></i></div>        
                </div>
            </>
        )
        
    } else if (props.type === 'active-call') {
        return (
            <>
                <div className='notification-icon'>
                    <i class="fa-solid fa-phone"></i>
                </div>


                <div className='notification-message'>
                    <div className='notification-title'>
                        {props.message}
                    </div>

                    <div className='notification-description'>
                        {props.description} {<CountUpTimer description={props.description} />}
                    </div>
                </div>

                <div className='notification-action'>
                    <div className='notification-no'
                        style={{color: 'red'}}
                        onClick={() => {
                            props.remove();
                        }}
                    ><i class="fa-solid fa-circle-xmark"></i></div>        
                </div>
            </>
        )
    } else if (props.type === 'text') {
        return (
            <>
                <div className='notification-icon'
                    style={{
                        color: props.iconColor ? props.iconColor : 'white',
                    }}
                >
                    {props.image ? <img src={props.image} alt="noti-icon" style={{width: "100%"}} /> : null}
                    {props.icon ? <i className={"fa-solid fa-" + props.icon}></i> : null}
                    {!props.image && !props.icon && <i className="fa-solid fa-info"></i>}
                    {/* {props.app && <i class="fa-solid fa-circle-info"></i>} */}
                </div>


                <div className='notification-message'
                    style={{width: '75%'}}
                    
                    onClick={() => {
                        props.remove();
                    }}
                >
                    <div className='notification-title'>
                        {props.message}
                    </div>

                    <div className='notification-description'>
                        {props.description}
                    </div>
                </div>

                <div className='notification-action'
                    style={{width:0}}
                ></div>
            </>
        )

    } else return null;
}

export default Notification;