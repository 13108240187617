import "./gang.css";
import { useState } from "react";
import { useEffect } from "react";

import Alert from "../../components/alert";

function Gang(props) {

    // const [gangData, setGangData] = useState({ // for testing
    //     name: "Ballas",
    //     color: "#7D00FF",
    //     members: [
    //         {
    //             name: "Leo Nardo",
    //             rank:  {
    //                 name: "Boss",
    //                 level: 3
    //             }
    //         },
    //         {
    //             name: "❌ Leonardo Dicaprio",
    //             rank: {
    //                 name: "Recruit",
    //                 level: 1
    //             }
    //         },
    //         {
    //             name: "❌ Christian Ice",
    //             rank: {
    //                 name: "Recruit",
    //                 level: 1
    //             }
    //         },
    //     ]
    // });

    const [gangData, setGangData] = useState(null);

    const [manage, setManage] = useState(false);
    const [currentManage, setCurrentManage] = useState(null);
    const [showInput, setShowInput] = useState(false);
    const [invites, setInvites] = useState([]);
    const [isBoss, setIsBoss] = useState(true);


    {
        if (!props.show)
        {
            return null;
        }

        if (gangData === null)
        {
            return (
                <div className="app-container" id="gang-app">
                    Gang Management
                    <span className="invites-title">Pending Invites:</span>
                    <div className="invites-wrapper">
                        {invites.map((invite, index) => {
                            return (
                                <div key={index} className="invite-item">
                                    <span
                                        style={{
                                            color: invite.color,
                                        }}
                                    >{invite.gang}</span>
                                    <div>
                                        <span className="invite-accept"
                                            onClick={() => {
                                            }}
                                        ><i class="fa-solid fa-circle-check"></i></span>
                                        <span className="invite-decline"
                                            onClick={() => {
                                                // remove invite from invites
                                                let newInvites = invites.filter((inv, i) => i !== index);
                                                setInvites(newInvites);
                                            }}
                                        ><i class="fa-solid fa-circle-xmark"></i></span>
                                    </div>
                                    
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }

        return (
            <>
                <div className="cid-input"
                    style={{
                        display: showInput ? 'flex' : 'none',
                    }}
                >
                    <span>Recruit Member:</span>
                    <input id="cid-input-text" type="text" placeholder="Citizen ID" />
                    <div className="invite-buttons-wrapper">
                        <span className="gang-invite-cancel"
                            onClick={() => {
                                setShowInput(!showInput);
                            }}
                        >Cancel</span>
                        <span className="gang-invite-submit"
                            onClick={() => {
                                let cid = document.getElementById("cid-input-text").value;
                                
                                setShowInput(!showInput);
                                // send phone notification to cid
                                // send phone notification to sender that invite was sent
                            }}
                        >Invite</span>
                        
                    </div>
                    
                </div>
                <div className="app-container" id="gang-app"
                    style = {{
                        backgroundColor: gangData.color,
                        boxShadow: 'inset 0 0 150px 0px ' + gangData.color
                    }}
                > 
                    <div className="gang-name-wrapper"
                        style = {{
                            color: gangData.color,
                            fontWeight: 'bold',
                        }}
                    >
                        <span>{gangData.name}</span>
                    </div>
                    <div className="gang-color-wrapper">
                    Color: 
                        <span 
                            style = {{
                                color: gangData.color,
                                fontWeight: 'bold',
                            }}
                        > {gangData.color} &nbsp; </span>
                        <span className="copy-color-button"
                            onClick={() => {
                                
                            }}
                        ><i class="fa-solid fa-copy"></i></span>
                    </div>
                    <div className="gang-management-wrapper">
                        <span className="gang-manage-button"
                            style={{
                                backgroundColor: 'rgba(255, 0, 0, 0.65)',
                            }}
                            onClick={() => {
                            }}
                        >
                            Leave
                        </span>
                        <span className="gang-manage-button"
                            style={{
                                backgroundColor: 'rgba(0, 255, 0, 0.65)',
                            }}
                            onClick={() => {
                                if (!isBoss) {
                                    return;
                                }
                                setShowInput(!showInput);
                            }}
                        >Invite</span>

                    </div>
                    <div className="gang-members-wrapper">
                       <span>Boss: </span> 

                       {gangData.members.map((member, index) => {
                            return (member.rank.name === 'Boss') &&
                            <div key={index} className="gang-member-wrapper"
                                style = {{
                                    marginBottom: '10px',
                                }}
                            >
                                <span>{member.name}</span>
                                <span>{member.rank.name}</span>
                            </div>

                        })}

                        <span>Members:</span>
                        
                        {gangData.members.map((member, index) => {
                            return (member.rank.name === 'Shot Caller') && (
                                <div key={index} className="gang-member-wrapper">
                                    <span>{member.name}</span>
                                    <span className="member-rank"
                                        style={{
                                            display: manage && member === currentManage ? 'none' : 'flex',
                                        }}
                                        onClick={() => {
                                            if (!isBoss) {
                                                return;
                                            }
                                            setManage(!manage);
                                            setCurrentManage(member);
                                        }}
                                    >{member.rank.name}</span>
                                    {/* dropdown */}
                                    <select name="rank-select" value={member.rank.name}
                                        style={{
                                            display: manage && member === currentManage ? 'flex' : 'none',
                                        }}
                                        onChange={(e) => {
                                            console.log(e.target.value);
                                            // set member rank to e.target.value
                                            member.rank.name = e.target.value;
                                            setManage(false);
                                        }}
                                    >
                                        <option value="Shot Caller">Shot Caller</option>
                                        <option value="Enforcer">Enforcer</option>
                                        <option value="Recruit">Recruit</option>
                                        <option value="fire" style={{
                                            color: 'red',
                                            fontWeight: 'bold',
                                        }}>Fire</option>
                                    </select>
                                </div>
                            );
                        })}

                        {gangData.members.map((member, index) => {
                            return (member.rank.name === 'Enforcer') && (
                                <div key={index} className="gang-member-wrapper">
                                    <span>{member.name}</span>
                                    <span className="member-rank"
                                        style={{
                                            display: manage && member === currentManage ? 'none' : 'flex',
                                        }}
                                        onClick={() => {
                                            if (!isBoss) {
                                                return;
                                            }
                                            setManage(!manage);
                                            setCurrentManage(member);
                                        }}
                                    >{member.rank.name}</span>
                                    {/* dropdown */}
                                    <select name="rank-select" value={member.rank.name}
                                        style={{
                                            display: manage && member === currentManage ? 'flex' : 'none',
                                        }}
                                        onChange={(e) => {
                                            console.log(e.target.value);
                                            // set member rank to e.target.value
                                            member.rank.name = e.target.value;
                                            setManage(false);
                                        }}
                                    >
                                        <option value="Shot Caller">Shot Caller</option>
                                        <option value="Enforcer">Enforcer</option>
                                        <option value="Recruit">Recruit</option>
                                        <option value="fire" style={{
                                            color: 'red',
                                            fontWeight: 'bold',
                                        }}>Fire</option>
                                    </select>
                                </div>
                            );
                        })}

                        {gangData.members.map((member, index) => {
                            return (member.rank.name === 'Recruit') && (
                                <div key={index} className="gang-member-wrapper"
                                    
                                >
                                    <span>{member.name}</span>
                                    <span className="member-rank"
                                        style={{
                                            display: manage && member === currentManage ? 'none' : 'flex',
                                        }}
                                        onClick={() => {
                                            if (!isBoss) {
                                                return;
                                            }
                                            setManage(!manage);
                                            setCurrentManage(member);
                                        }}
                                    >{member.rank.name}</span>
                                    {/* dropdown */}
                                    <select name="rank-select" value={member.rank.name}
                                        style={{
                                            display: manage && member === currentManage ? 'flex' : 'none',
                                        }}
                                        onChange={(e) => {
                                            //console.log(e.target.value);
                                            // set member rank to e.target.value
                                            member.rank.name = e.target.value;
                                            // change value in dropdown
                                            setManage(false);
                                            
                                        }}
                                    >
                                        <option value="Shot Caller">Shot Caller</option>
                                        <option value="Enforcer">Enforcer</option>
                                        <option value="Recruit">Recruit</option>
                                        <option value="fire" style={{
                                            color: 'red',
                                            fontWeight: 'bold',
                                        }}>Fire</option>
                                    </select>
                                </div>
                            );
                        })}
                    </div>
                    
                </div>
                
            </>
        );
    }
}

export default Gang;