import {React, useState} from "react";

import "./wallet.css";

function Account(props) {
    return (
        <div className="wallet-account-container">
            <div className="wallet-account-name">{props.name}</div>
            <div className="wallet-account-balance">
                <span>{props.balance}</span>
                <span className="available-balance-text">Available Balance</span>
            </div>
        </div>
    );
}

function SendPayment(props) {
    return (
        <div className="send-payment-wrapper"
            style={{display: props.show ? "flex" : "none"}}
        >
            <div className="send-payment-header">
                <span>Send Payment</span>
                <span className="send-payment-close"
                    onClick={() => props.setShowSendPayment(false)}
                ><i class="fa-solid fa-square-xmark"></i></span>
            </div>

            <div className="send-payment-form">
                <span className="send-payment-input-wrapper">To: <input className="send-payment-input" id="send-payment-id" type="text" placeholder="Server ID" /></span>
                <span className="send-payment-input-wrapper">Amount: <input className="send-payment-input" id="send-payment-input" type="text" placeholder="Amount" /></span>
            </div>

            <div className="send-payment-button"
                onClick={() => {
                    // check if input for amount is a number
                    var amount_input = document.querySelector("#wallet-app .send-payment-form .send-payment-input-wrapper #send-payment-input").value;
                    if (isNaN(amount_input)) {
                        console.log("Amount input is not a number");
                        return;
                    }

                    props.setShowSendPayment(false)
                }}
            >Send</div>
        </div>
    );
}

function RequestPayment(props) {
    return (
        <div className="request-payment-wrapper"
            style={{display: props.show ? "flex" : "none"}}
        >
            <div className="request-payment-header">
                <span>Request Payment</span>
                <span className="request-payment-close"
                    onClick={() => props.setShowRequestPayment(false)}
                ><i class="fa-solid fa-square-xmark"></i></span>
            </div>

            <div className="request-payment-form">
                <span className="request-payment-input-wrapper">From: <input className="request-payment-input" id = "request-id" type="text" placeholder="Server ID" /></span>
                <span className="request-payment-input-wrapper">Amount: <input className="request-payment-input" id = "request-amount" type="text" placeholder="Amount" /></span>
            </div>

            <div className="request-payment-button"
                onClick={() => {
                    
                    // check if input for amount is a number
                    var amount_input = document.querySelector("#wallet-app .request-payment-form .request-payment-input-wrapper #request-amount").value;
                    if (isNaN(amount_input)) {
                        console.log("Amount input is not a number");
                        return;
                    }

                    props.setShowRequestPayment(false)

                }}
            >Request</div>
        </div>
    );
}

function Wallet(props) {

    const [showInvoice, setShowInvoice] = useState(false);
    const [showCreateInvoice, setShowCreateInvoice] = useState(false);
    const [showSendPayment, setShowSendPayment] = useState(false);
    const [showRequestPayment, setShowRequestPayment] = useState(false);

    const [invoiceTab, setInvoiceTab] = useState("my-invoices");

    const [myinvoices, setInvoices] = useState([
        {
            id: 1,
            amount: 1200000,
            sender: "Ammunation (Elgin)",
            date: "07/17/2024",
            paid: 0
        },
        {
            id: 2,
            amount: 2000,
            sender: "Jane Doe",
            date: "07/17/2024",
            paid: 1
        },
    ]);

    const [sentInvoices, setSentInvoices] = useState([
        {
            id: 1,
            amount: 1000,
            billed_name: "John Doe",
            date: "07/17/2024",
            paid: 0
        },
        {
            id: 2,
            amount: 2000,
            billed_name: "Jane Doe",
            date: "07/17/2024",
            paid: 1
        },
    ]);

    const [Accounts, setAccounts] = useState([
        {
            name: "Personal",
            balance: "$747,000.00"
        },
        {
            name: "Ammunation",
            balance: "$1,523,000.00"
        },
    ]);

    
        if (!props.show) {
            return null;
        }
    
    return (
            <div className="app-container" id="wallet-app">

                <SendPayment show={showSendPayment} setShowSendPayment={setShowSendPayment} />
                <RequestPayment show={showRequestPayment} setShowRequestPayment={setShowRequestPayment} />

                <div className="create-invoice-wrapper"
                    style={{display: showCreateInvoice ? "flex" : "none"}}
                >
                    <div className="create-invoice-header">
                        <span style={{
                            color: 'green',
                            textShadow: '0px 0px 2.5px green'
                        }}>New Invoice</span>
                        <span className="create-invoice-close"
                            onClick={() => setShowCreateInvoice(false)}
                        ><i class="fa-solid fa-square-xmark"></i></span>
                    </div>

                    <div className="create-invoice-form">
                        <span className="invoice-input-wrapper">Server ID: <input className="invoice-input" id= "invoice-to-input" type="text" placeholder="Server ID" /></span>
                        <span className="invoice-input-wrapper">Amount: <input className="invoice-input" type="text" id= "invoice-amount-input" placeholder="Amount" /></span>
                    </div>

                    <div className="send-invoice-button"
                        onClick={() => {
                            setShowCreateInvoice(false)
                        }}
                    >Send</div>
                </div>

                <div className="invoice-wrapper" 
                    style={{display: showInvoice ? "flex" : "none"}}
                >
                    <span className="invoice-header">
                        <span>Invoices</span>
                        <span className="invoice-close"
                            onClick={() => setShowInvoice(false)}
                        ><i class="fa-solid fa-square-xmark"></i></span>
                    </span>

                    <span className="invoice-header"
                        style={{ padding: 0}}
                    >
                        <span
                            style = {{
                                border: invoiceTab === "my-invoices" ? "1px solid #fff" : "none",
                                padding: '5px',
                                cursor: 'pointer',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            onClick={() => setInvoiceTab("my-invoices")}
                        >My Invoices</span>
                        <span
                            style = {{
                                border: invoiceTab === "sent-invoices" ? "1px solid #fff" : "none",
                                padding: '5px',
                                cursor: 'pointer',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            onClick={() => setInvoiceTab("sent-invoices")}
                        >Sent Invoices</span>
                    </span>

                    <div className="invoice-list">
                        {invoiceTab === "my-invoices" ?
                            myinvoices.slice().reverse().map((invoice) => (
                                <div className="invoice-item">
                                    <span className="invoice-info">
                                        <span>{invoice.sender}</span>
                                        <span className="invoice-date">{invoice.date}</span>
                                    </span>

                                    <span className="invoice-info">
                                        <span className="invoice-amount">${invoice.amount}</span>
                                    
                                        <span>
                                            {invoice.paid === 1 ?
                                                <span className="invoice-status-paid">Paid &nbsp; <i class="fa-solid fa-square-check"></i></span> :
                                                <span className="invoice-status-pay-button"
                                                    onClick={() => {
                                                    }}
                                                >Pay</span>
                                            }
                                        </span>
                                    </span>
                                </div>
                            )) :

                            sentInvoices.slice().reverse().map((invoice) => (
                                <div className="invoice-item">
                                    <span className="invoice-info">
                                        <span>{invoice.billed_name}</span>
                                        <span className="invoice-date">{invoice.date}</span>
                                    </span>


                                    <span className="invoice-info">
                                        <span className="invoice-amount">${invoice.amount}</span>

                                        <span>
                                            {invoice.paid === 1 ?
                                                <span className="invoice-sent-status-paid"
                                                    style={{color: 'green'}}
                                                >Paid</span> :
                                                <span className="invoice-sent-status-paid"
                                                    style={{color: 'rgba(255, 0, 0, 0.75)'}}
                                                >Unpaid</span>
                                            }
                                        </span>
                                    </span>
                                </div>
                            ))
                        }
                    </div>

                    <div className="create-invoice-button"
                        onClick={() => setShowCreateInvoice(true)}
                    >Create Invoice</div>

                </div>


                <div className="wallet-header">Wallet</div>

                <div className="wallet-actions">
                    <div className="wallet-action"
                        onClick={() => setShowSendPayment(true)}
                    > Send &nbsp; <i class="fa-solid fa-money-bill"></i></div>
                    <div className="wallet-action"
                        onClick={() => setShowRequestPayment(true)}
                    > Request &nbsp; <i class="fa-solid fa-hand-holding-dollar"></i></div>
                </div>
                <div className="wallet-action" id="invoice-button"
                    onClick={() => setShowInvoice(true)}
                > Invoice &nbsp; <i class="fa-solid fa-file-invoice-dollar"></i> </div>

                <div className="wallet-accounts"> 
                    {/* <div className="wallet-accounts-title">Accounts</div> */}
                    
                        {Accounts.map((account) => (
                            <Account name={account.name} balance={account.balance} />
                        ))}
                    

                </div>
            </div>
    );
}

export default Wallet;