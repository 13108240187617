import { useState, React } from 'react';

import "./garage.css";

function VehicleItem(props) {
    return (
        <div className='vehicle-item-wrapper'>
            <div className='vehicle-item-upper'>
                <div className='vehicle-name'>{props.name}</div>
                <div className='vehicle-plate'>{props.plate}</div>
                
            </div>
            <div className='vehicle-item-mid'>
                
                <span>Location: </span>
                <span 
                    style={{
                        color: props.state == 1 ? 'green' : 'red',
                        fontWeight: 'bold'
                    }}
                >
                    {props.state == 1 ? props.garage : 'Out'}
                </span>
                
            </div>
            <div className='vehicle-item-lower'>
                <div className='vehicle-stat'><i class="ph-bold ph-gas-pump"></i> {props.fuel}%</div>
                <div className='vehicle-stat'><i class="ph-bold ph-car"></i> {props.body}%</div>
                <div className='vehicle-stat'><i class="ph-bold ph-engine"></i> {props.engine}%</div>
            </div>
        </div>
    );
}

function Garage(props) {

        const [vehicles, setVehicles] = useState([
            {
                name: 'Adder',
                plate: '1234567',
                fuel: 100,
                body: 100,
                engine: 100,
                state: 1,
                garage: 'PillBox Garage'
            },
            {
                name: 'Ferrari',
                plate: '1234567',
                fuel: 100,
                body: 100,
                engine: 100,
                state: 0,
                garage: 'PillBox Garage'
            },
            {
                name: 'Adder',
                plate: '1234567',
                fuel: 100,
                body: 100,
                engine: 100,
                state: 1,
                garage: 'PillBox Garage'
            },
            {
                name: 'Ferrari',
                plate: '1234567',
                fuel: 100,
                body: 100,
                engine: 100,
                state: 0,
                garage: 'PillBox Garage'
            },
        ]);



        if (!props.show) {
            return null;
        }
    
    return (
        <div className="app-container" id="garage-app">
            <div className='garage-header'>Garage</div>
            <div className='vehicles-wrapper'>
                {vehicles.map((vehicle, index) => {
                    return <VehicleItem 
                    key={index} 
                    name={vehicle.name} 
                    plate={vehicle.plate} 
                    fuel={vehicle.fuel} 
                    body={vehicle.body} 
                    engine={vehicle.engine} 
                    state={vehicle.state} 
                    garage={vehicle.garage}
                    />
                })}
            </div>
        </div>
    );
}

export default Garage;